import { deleteGoal, updateGoal } from "../features/goals/goalSlice";
import { createMessage } from "../features/messages/messageSlice";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSongs, reset } from "../features/songs/songSlice";
import SongItem from "./SongItem";
import GoalForm from "./GoalForm";
import GoalEditForm from "./GoalEditForm";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaExpandAlt, FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

function GoalItem({ goal }) {
  const [status, setStatus] = useState("");

  const [showSongs, setShowSongs] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [showArtists, setShowArtists] = useState(false);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { songs, isLoading, isError, message } = useSelector(
    (state) => state.songs
  );

  const filteredSongs = songs.filter((song) => song.albumId === goal.albumId);
  filteredSongs.sort(
    (a, b) => parseInt(a.songNumber, 10) - parseInt(b.songNumber, 10)
  );

  const handleDownload = async () => {
    const imageU = goal.imageUrl; // Replace with your actual image URL

    const fileUrls = filteredSongs.map((song) => song.audioUrl);
    let trackNames = "";
    for (let i = 0; i < filteredSongs.length; i++) {
      trackNames += filteredSongs[i].name;
      trackNames += ",";
    }
    trackNames += "END";
    //const fileUrls = [];
    fileUrls.push(imageU);
    console.log(fileUrls);

    let trackArtists = "";
    for (let i = 0; i < filteredSongs.length; i++) {
      let artistsStr = "";
      for (let j = 0; j < filteredSongs[i].artists.length; j++) {
        let flag = false;
        for (let k = 0; k < goal.artists.length; k++) {
          if (
            filteredSongs[i].artists[j].artistName == goal.artists[k].artistName
          ) {
            flag = true;
          }
        }
        if (flag == false) {
          artistsStr += filteredSongs[i].artists[j].artistName;
          artistsStr += "_";
        }
      }
      if (artistsStr == "") {
        trackArtists += "none,";
      } else {
        trackArtists += artistsStr;
        trackArtists += ",";
      }
    }
    trackArtists += "END";

    toast("Download in progress. This may take up to a few minutes.");

    try {
      const response = await fetch(
        `/download?fileUrls=${JSON.stringify(
          fileUrls
        )}&trackNames=${trackNames}&albumName=${
          goal.text
        }&trackArtists=${trackArtists}`
      );
      const blob = await response.blob();

      // Create a download link and trigger the file download
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${goal.text}_files.zip`;
      downloadLink.click();
    } catch (error) {
      console.error(error);
      // Handle error if download fails
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    /*dispatch(
      updateGoal(
        {
          status: status,
        },
        goal._id
      )
    );*/
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.put("/api/goals/" + goal._id, { status: status }, config);
    /*const userId = goal.user.id;
    const email = goal.email;
    const name = goal.name;
    const message = status;*/
    /*dispatch(
      createMessage({
        userId,
        email,
        name,
        message,
      })
    );*/
    const messageData = {
      userId: goal.user, // the ID of the user that the message is for
      email: "temp",
      name: "temp",
      messageStr: status,
      album: goal.text,
    };
    axios.post("/api/messages/", messageData, config);

    axios
      .post("/send-email", {
        to: goal.accountEmail,
        subject: `A status update is available for your album ${goal.text}`,
        text: `A status update is available for your album ${goal.text}: ${status}`,
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.error(err));
    window.location.reload(false);
  };

  useEffect(() => {
    dispatch(getSongs());

    return () => {
      dispatch(reset());
    };
  }, [user]);

  return (
    <div className="goal">
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {/*<div>{new Date(goal.createdAt).toLocaleString("en-US")}</div>*/}
        <div>
          <h2>Album Title: {goal.text}</h2>
          {user && user.role == "admin" && (
            <>
              <h4 style={{ fontWeight: "normal" }}>
                <b>Account Username:</b>
                <br /> {goal.accountName}
              </h4>
              <h4 style={{ fontWeight: "normal" }}>
                <b>Account Email:</b>
                <br /> {goal.accountEmail}
              </h4>
            </>
          )}
          <Popup
            modal
            nested
            trigger={
              <button
                className="close2"
                type="button"
                style={{
                  fontSize: "30px",
                  background: "none",
                  //border: "solid 1px",
                  //margin: "5px 5px 5px 5px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
              >
                <FaEdit />
              </button>
            }
            position="right center"
            //className="my-popup"
          >
            <div className="">
              <p>Edit Album</p>
              <GoalEditForm goal={goal} />
            </div>
          </Popup>
          <img
            src={goal.imageUrl}
            style={{
              width: "200px",
              border: "solid 3px black",
              borderRadius: "5px",
            }}
          />
          <br />
          <button
            onClick={() => {
              if (showArtists) {
                setShowArtists(false);
              } else {
                setShowArtists(true);
              }
            }}
          >
            Show Artists
          </button>
          {showArtists && (
            <>
              {" "}
              <h3>Album Artists: </h3>
              {goal.artists &&
                goal.artists[0] &&
                goal.artists.map((art) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h3 key={art.artistName}>{art.artistName} </h3>
                    <Popup
                      trigger={
                        <button
                          type="button"
                          style={{
                            fontSize: "15px",
                            background: "none",
                            border: "solid 1px",
                            margin: "5px 5px 5px 5px",
                            cursor: "pointer",
                            borderRadius: "3px",
                          }}
                        >
                          Show Artist Details
                          <FaExpandAlt />
                        </button>
                      }
                      position="right center"
                      //className="my-popup"
                    >
                      <div className="">
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Artist Name:
                          </span>{" "}
                          {art.artistName}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Artist Role:
                          </span>{" "}
                          {art.role}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Spotify URI:
                          </span>{" "}
                          {art.spotifyURI}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Apple ID:</span>{" "}
                          {art.appleId}
                        </p>
                      </div>
                    </Popup>
                  </div>
                ))}
            </>
          )}
          <br />
          <button
            onClick={() => {
              if (showMetadata) {
                setShowMetadata(false);
              } else {
                setShowMetadata(true);
              }
            }}
          >
            Show Metadata
          </button>
          {showMetadata && (
            <>
              <h3>Writers:</h3>
              {goal.writers.length == 0 && <h4>None</h4>}
              {goal.writers &&
                goal.writers[0] &&
                goal.writers.map((writer) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h4 key={writer.writerName}>{writer.writerName} </h4>
                  </div>
                ))}
              <h3>Producers:</h3>
              {goal.producers.length == 0 && <h4>None</h4>}
              {goal.producers &&
                goal.producers[0] &&
                goal.producers.map((producer) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h4 key={producer.producerName}>
                      {producer.producerName}{" "}
                    </h4>
                  </div>
                ))}
              {user && user.role && (
                <>
                  <h4>
                    Release Date: {goal.releaseDate.toString().substr(0, 10)}
                  </h4>
                  <h4>Live Date: {goal.liveDate.substr(0, 10)}</h4>
                  <h4>© Line: {goal.cLine}</h4>
                  <h4>℗ Line: {goal.pLine}</h4>
                  <h4>Label Name: {goal.labelName}</h4>
                  <h4>UPC: {goal.upc}</h4>
                  <h4>Primary Genre: {goal.primaryGenre}</h4>
                  <h4>Secondary Genre: {goal.secondaryGenre}</h4>
                </>
              )}
            </>
          )}
          <br />
          <button
            onClick={() => {
              if (showSongs) {
                setShowSongs(false);
              } else {
                setShowSongs(true);
              }
            }}
          >
            Show Tracklist
          </button>
          {showSongs && (
            <>
              <h3>Songs: </h3>
              <div className="songs">
                {filteredSongs.map((song) => (
                  <SongItem key={song._id} song={song} goal={goal} />
                ))}
              </div>
            </>
          )}
          <br />
          {user && user.role == "admin" && (
            <button onClick={handleDownload}>Download Files</button>
          )}
          <h4>Live Date: {goal.liveDate.substr(0, 10)}</h4>
        </div>

        <div style={{}}>
          <h3
            style={{
              color:
                goal.status == "pending approval by team"
                  ? "#b5a000"
                  : goal.status == "Album rejected."
                  ? "red"
                  : goal.status == "Album approved!"
                  ? "green"
                  : "black",
            }}
          >
            Status: {goal.status}
          </h3>
          {user && user.role == "admin" && (
            <section className="form">
              <h4>Update status:</h4>
              <button
                className="btn btn-submit"
                type="button"
                style={{ marginTop: "20px", width: "50%" }}
                onClick={async () => {
                  //setStatus("Album approved!");
                  const config = {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  };
                  axios.put(
                    "/api/goals/" + goal._id,
                    { status: "Album approved!" },
                    config
                  );
                  const messageData = {
                    userId: goal.user, // the ID of the user that the message is for
                    email: "temp",
                    name: "temp",
                    messageStr: status,
                    album: goal.text,
                  };
                  console.log(messageData);
                  axios.post(
                    "/api/messages/",
                    {
                      userId: goal.user,
                      email: "temp",
                      name: "temp",
                      messageStr: "Album approved!",
                      album: goal.text,
                    },
                    config
                  );
                  axios
                    .post("/send-email", {
                      to: goal.accountEmail,
                      subject: `A status update is available for your album ${goal.text}`,
                      text: `A status update is available for your album ${goal.text}: Album approved!`,
                    })
                    .then((res) => console.log(res.data))
                    .catch((err) => console.error(err));
                  window.location.reload(false);
                }}
              >
                Approve
              </button>
              <button
                className="btn btn-submit"
                type="button"
                style={{ marginTop: "20px", width: "50%" }}
                onClick={async () => {
                  //setStatus("Album rejected.");
                  const config = {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  };
                  axios.put(
                    "/api/goals/" + goal._id,
                    { status: "Album rejected." },
                    config
                  );
                  const messageData = {
                    userId: goal.user, // the ID of the user that the message is for
                    email: "temp",
                    name: "temp",
                    messageStr: "Album rejected.",
                    album: goal.text,
                  };
                  console.log(messageData);
                  axios.post(
                    "/api/messages/",
                    {
                      userId: goal.user,
                      email: "temp",
                      name: "temp",
                      messageStr: "Album rejected.",
                      album: goal.text,
                    },
                    config
                  );
                  axios
                    .post("/send-email", {
                      to: goal.accountEmail,
                      subject: `A status update is available for your album ${goal.text}`,
                      text: `A status update is available for your album ${goal.text}: Album rejected.`,
                    })
                    .then((res) => console.log(res.data))
                    .catch((err) => console.error(err));
                  window.location.reload(false);
                }}
              >
                Reject
              </button>
              <form onSubmit={onSubmit}>
                <div className="form-container">
                  <div className="form-group">
                    <label htmlFor="text">Send custom status message:</label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-submit"
                      type="submit"
                      style={{ marginTop: "20px", width: "50%" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </section>
          )}
        </div>
      </div>

      {user && user.email == "admin@thesystemrecords.com" && (
        <button
          onClick={() => {
            dispatch(deleteGoal(goal._id));
          }}
          className="close"
        >
          X
        </button>
      )}
    </div>
  );
}

export default GoalItem;
