import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { register, reset, logout } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";

function RegisterArtist() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    role: "artist",
    isApproved: "true",
  });

  const { name, email, password, password2, role, isApproved } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    /*if (isError) {
      toast.error(message);
    }*/

    if (isSuccess) {
      toast("Artist account created.");
      dispatch(logout());
      dispatch(reset());
      navigate("/login");
    }

    //dispatch(reset());
  }, [isSuccess]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (password !== password2) {
      toast.error("Passwords do not match");
    } else if (name == "" || email == "" || password == "") {
      toast.error("Please fill out all fields");
    } else {
      const userData = {
        name,
        email,
        password,
        role,
        isApproved,
      };

      dispatch(register(userData));
      //toast("Admin account created.");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!user || user.role != "admin") {
    return <p>You are not authorized to register an artist.</p>;
  }

  return (
    <>
      <section className="heading">
        <h1>
          <FaUser />
          Register an Artist
        </h1>
        <p>Create an account</p>
      </section>
      <section className="form">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={name}
              placeholder="Enter artist name"
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={email}
              placeholder="Enter artist email"
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={password}
              placeholder="Enter artist password"
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              id="password2"
              name="password2"
              value={password2}
              placeholder="Confirm artist password"
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-block">
              Submit
            </button>
          </div>
        </form>
      </section>
    </>
  );
}

export default RegisterArtist;
