import { deleteMessage } from "../features/messages/messageSlice";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

function MessageItem({ msg }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  return (
    <div className="msg">
      {/*<div>{new Date(goal.createdAt).toLocaleString("en-US")}</div>*/}
      <h3>
        A new status update is available for {msg.album}: "
        <span style={{ fontSize: "16px" }}>
          <br />
          {msg.message}
        </span>
        "
      </h3>
      <button
        onClick={() => {
          dispatch(deleteMessage(msg._id));
        }}
        className="close"
        style={{
          backgroundColor: "red",
          borderRadius: "3px",
          color: "white",
          padding: "3px 3px 3px 3px",
        }}
      >
        DISMISS
      </button>
    </div>
  );
}

export default MessageItem;
