import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MessageItem from "../components/MessageItem";
import Spinner from "../components/Spinner";
import { getMessages, reset } from "../features/messages/messageSlice";

function Messages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { messages, isLoading, isError, message } = useSelector(
    (state) => state.messages
  );

  const sortedMessages = [...messages].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      navigate("/login");
      console.log("yeetDash");
    }

    dispatch(getMessages());

    return () => {
      dispatch(reset());
    };
  }, [user]);

  if (!user) {
    return <p>Your session has expired. Please log back in.</p>;
  }

  if (user && user.isApproved == "false") {
    return (
      <p>
        Your account must be approved by a moderator before you can upload
        albums.
      </p>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="dash">
      <section className="content">
        {user.role != "admin" && <h1>Your Messages</h1>}
        {messages.length > 0 ? (
          <div className="msgs">
            {sortedMessages.map((msg) => (
              <MessageItem key={msg._id} msg={msg} />
            ))}
          </div>
        ) : (
          <div>{user.role != "admin" && <h3>You have no messages</h3>}</div>
        )}
      </section>
    </div>
  );
}

export default Messages;
