import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSavedArtists,
  createSavedArtist,
  reset,
} from "../features/savedArtists/savedArtistSlice";
import { createSong } from "../features/songs/songSlice";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

function SongForm(props) {
  const [songName, setSongName] = useState("");
  const [songArtist, setSongArtist] = useState("");
  const [isrc, setIsrc] = useState("auto-generate");
  const [previewStartTime, setPreviewStartTime] = useState("None");

  const [songNumber, setSongNumber] = useState(props.num);

  const [audioFile, setAudioFile] = useState(null);

  const [album, setAlbum] = useState(props.album);

  const [artists, setArtists] = useState(props.artists);

  const [writers, setWriters] = useState(props.writers);
  const [producers, setProducers] = useState(props.producers);

  const [formCompleted, setFormCompleted] = useState(false);

  const [firstRenderDone, setFirstRenderDone] = useState(false);

  const artistRoleOptions = [
    { value: "Primary", label: "Primary" },
    { value: "Featured", label: "Featured" },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { savedArtists, isLoading, isError, message } = useSelector(
    (state) => state.savedArtists
  );

  let artistOptions = savedArtists.map((artist) => ({
    value: artist._id,
    label: artist.artistName,
  }));

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      //navigate("/login");
      console.log("yeetDash");
    }

    if (user) {
      dispatch(getSavedArtists());
      artistOptions = savedArtists.map((artist) => ({
        value: artist._id,
        label: artist.artistName,
      }));
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  /*useImperativeHandle(ref, () => ({
    getFormCompleted: () => formCompleted,
  }));*/

  useEffect(() => {
    setFirstRenderDone(true);
  }, []);

  useEffect(() => {
    setAlbum(props.album);
  }, [props.album]);

  useEffect(() => {
    if (props.trigger) {
      console.log("Triggered: ", songNumber);

      // get secure url from our server
      /*const { url } = await fetch("/s3Url").then((res) => res.json());
      console.log(url);

      // post the image direclty to the s3 bucket
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: audioFile,
      });

      const audioUrl = url.split("?")[0];*/

      //const audioUrl = gets3Url();

      gets3Url();
    }
  }, [props.trigger]);

  useEffect(() => {
    if (
      firstRenderDone &&
      songName != "" &&
      //songArtist != "" &&
      isrc != "" &&
      previewStartTime != "" &&
      audioFile != null &&
      artists.length > 0 &&
      writers.length > 0
    ) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  }, [songName, songArtist, isrc, audioFile]);

  useEffect(() => {
    if (firstRenderDone) {
      if (formCompleted) {
        props.increment();
      } else {
        props.decrement();
      }
    }
  }, [formCompleted]);

  function addWriter() {
    setWriters([
      ...writers,
      {
        writerName: "Writer Name",
      },
    ]);
  }

  function deleteWriter(index) {
    const updatedWriters = [...writers];
    updatedWriters.splice(index, 1);
    setWriters(updatedWriters);
  }

  function handleWriterNameChange(e, index) {
    const updatedWriters = [...writers];
    updatedWriters[index].writerName = e.target.value;
    setWriters(updatedWriters);
  }

  function addProducer() {
    setProducers([
      ...producers,
      {
        producerName: "Producer Name",
      },
    ]);
  }

  function deleteProducer(index) {
    const updatedProducers = [...producers];
    updatedProducers.splice(index, 1);
    setProducers(updatedProducers);
  }

  function handleProducerNameChange(e, index) {
    const updatedProducers = [...producers];
    updatedProducers[index].producerName = e.target.value;
    setProducers(updatedProducers);
  }

  function addArtist() {
    setArtists([
      ...artists,
      {
        artistName: "Artist Name",
        spotifyURI: "New Artist",
        appleId: "New Artist",
        role: "primary",
      },
    ]);
  }

  function deleteArtist(index) {
    const updatedArtists = [...artists];
    updatedArtists.splice(index, 1);
    setArtists(updatedArtists);
  }

  function handleArtistNameChange(e, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].artistName = e.target.value;
    setArtists(updatedArtists);
  }

  function handleSpotifyURIChange(e, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].spotifyURI = e.target.value;
    setArtists(updatedArtists);
  }

  function handleAppleIdChange(e, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].appleId = e.target.value;
    setArtists(updatedArtists);
  }

  function handleRoleChange(selectedOption, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].role = selectedOption.value;
    setArtists(updatedArtists);
  }

  function handleArtistSelect(selectedOption, index) {
    const filteredArray = savedArtists.filter((obj) => {
      return obj._id.toString() == selectedOption.value;
    });
    const updatedArtists = [...artists];
    updatedArtists[index].artistName = filteredArray[0].artistName;
    updatedArtists[index].role = filteredArray[0].role;
    updatedArtists[index].spotifyURI = filteredArray[0].spotifyURI;
    updatedArtists[index].appleId = filteredArray[0].appleId;
    setArtists(updatedArtists);
  }

  function saveArtist(art) {
    //const updatedArtists = [...artists];
    //updatedArtists[index].role = selectedOption.value;
    //setArtists(updatedArtists);
    const artistName = art.artistName;
    const role = art.role;
    const spotifyURI = art.spotifyURI;
    const appleId = art.appleId;
    dispatch(
      createSavedArtist({
        artistName,
        role,
        spotifyURI,
        appleId,
      })
    );
    dispatch(reset());
    dispatch(getSavedArtists());
    artistOptions = savedArtists.map((artist) => ({
      value: artist._id,
      label: artist.artistName,
    }));

    toast("Artist info saved!");
  }

  const handleFile = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const validFileTypes = ["audio/wav", "audio/x-wav", "audio/vnd.wave"];
    const validFileType = validFileTypes.includes(fileType);

    if (!validFileType) {
      alert("Please upload a WAV file");
      return;
    }

    setAudioFile(e.target.files[0]);
  };

  const gets3Url = async () => {
    // get secure url from our server
    const { url } = await fetch("/s3Url").then((res) => res.json());
    console.log(url);

    // post the image direclty to the s3 bucket
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "Audio/x-wav",
      },
      body: audioFile,
    });

    const audioUrl = url.split("?")[0];

    /*if (previewStartTime == "") {
      setPreviewStartTime("None");
    }*/

    dispatch(
      createSong({
        songName,
        songNumber,
        //songArtist,
        isrc,
        album,
        audioUrl,
        previewStartTime,
        artists,
        writers,
        producers,
      })
    );
  };

  /*const setFileToBase = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAudioFile(reader.result);
      console.log(reader.result);
    };
  });*/

  return (
    <div>
      <ToastContainer />
      <h2>Song {props.num}</h2>
      <section className="form">
        <form>
          <div className="form-group">
            <label htmlFor="text">
              Song Name <span style={{ color: "#bd0000" }}>*</span>
            </label>
            <input
              type="text"
              name="text"
              id="text"
              value={songName}
              onChange={(e) => {
                setSongName(e.target.value);
              }}
            />
            <label htmlFor="text">
              Artist(s) <span style={{ color: "#bd0000" }}>*</span>
            </label>
            {artists.map((art, index) => (
              <div
                key={index}
                className="goal"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <label>{art.artistName}</label>
                <div>
                  <Popup
                    trigger={
                      <button
                        type="button"
                        style={{
                          fontSize: "20px",
                          background: "none",
                          border: "none",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <FaEdit />
                      </button>
                    }
                    position="center"
                    className="my-popup"
                    modal
                  >
                    <div className="form-group">
                      {savedArtists && savedArtists.length > 0 && (
                        <div>
                          <label>Select saved artist:</label>
                          <Select
                            defaultValue={null}
                            onChange={(selected) => {
                              handleArtistSelect(selected, index);
                            }}
                            options={artistOptions}
                          />
                        </div>
                      )}
                      <label htmlFor="text">
                        Role <span style={{ color: "#bd0000" }}>*</span>
                      </label>
                      <Select
                        defaultValue={art.role}
                        onChange={(selectedValue) => {
                          handleRoleChange(selectedValue, index);
                        }}
                        options={artistRoleOptions}
                      />
                      <div style={{ paddingBottom: "20px" }}></div>
                      <label>
                        Artist Name: <span style={{ color: "#bd0000" }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={art.artistName}
                        onChange={(e) => handleArtistNameChange(e, index)}
                      />

                      <label>
                        Spotify URI (e.g.
                        spotify:artist:6kw4tIoctSJkUveo6rkW9u):
                        {art.spotifyURI != "New Artist" &&
                          art.spotifyURI.length != 37 && (
                            <>
                              <br />
                              <span style={{ color: "red" }}>
                                Please enter a valid Spotify URI or "New Artist"
                              </span>
                            </>
                          )}
                      </label>
                      <input
                        type="text"
                        value={art.spotifyURI}
                        onChange={(e) => handleSpotifyURIChange(e, index)}
                      />
                      <label>Apple Id (e.g. 1523332292):</label>
                      <input
                        type="text"
                        value={art.appleId}
                        onChange={(e) => handleAppleIdChange(e, index)}
                      />
                    </div>
                    <button
                      type="button"
                      className="chill-btn"
                      onClick={() => saveArtist(art)}
                    >
                      Save Artist
                    </button>
                  </Popup>
                  <button
                    type="button"
                    onClick={() => deleteArtist(index)}
                    style={{
                      fontSize: "20px",
                      background: "none",
                      border: "none",
                      color: "#bd0000",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            ))}
            <button type="button" className="chill-btn" onClick={addArtist}>
              Add Artist <FaPlus />
            </button>
            <label htmlFor="text">
              Writer(s) <span style={{ color: "#bd0000" }}>*</span>
            </label>
            {writers.map((writer, index) => (
              <div
                key={index}
                //className="goal"
                //style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <label>
                    Writer {index + 1}{" "}
                    <button
                      type="button"
                      onClick={() => deleteWriter(index)}
                      style={{
                        fontSize: "20px",
                        background: "none",
                        border: "none",
                        color: "#bd0000",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </label>
                  <input
                    type="text"
                    value={writer.writerName}
                    onChange={(e) => handleWriterNameChange(e, index)}
                  />
                </div>
              </div>
            ))}
            <button type="button" className="chill-btn" onClick={addWriter}>
              Add Writer <FaPlus />
            </button>
            <label htmlFor="text">Producer(s)</label>
            {producers.map((producer, index) => (
              <div
                key={index}
                //className="goal"
                //style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <label>
                    Producer {index + 1}{" "}
                    <button
                      type="button"
                      onClick={() => deleteProducer(index)}
                      style={{
                        fontSize: "20px",
                        background: "none",
                        border: "none",
                        color: "#bd0000",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </label>
                  <input
                    type="text"
                    value={producer.producerName}
                    onChange={(e) => handleProducerNameChange(e, index)}
                  />
                </div>
              </div>
            ))}
            <button type="button" className="chill-btn" onClick={addProducer}>
              Add Producer <FaPlus />
            </button>
            <label htmlFor="text">ISRC (optional) </label>
            <input
              type="text"
              name="text"
              id="text"
              value={isrc}
              onChange={(e) => {
                setIsrc(e.target.value);
              }}
            />
            <label htmlFor="text">
              Start Time for Tiktok e.g. 01:30 (optional)
            </label>
            <input
              type="text"
              name="text"
              id="text"
              value={previewStartTime}
              onChange={(e) => {
                setPreviewStartTime(e.target.value);
              }}
            />
            <label htmlFor="text">
              Audio file <span style={{ color: "#bd0000" }}>*</span>
            </label>
            <input
              type="file"
              name="audio"
              id="formupload"
              onChange={handleFile}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

//const forwardedSongForm = forwardRef(SongForm);

export default SongForm;
